<template>
    <div class="mx-auto mt-20 text-center text-white">
        <h1 class="text-3xl mb-4">404</h1>
        <h2 class="text-xl">You are lost. Please go back.</h2>
    </div>
</template>
<script>
export default {
    name: 'NotFound'
}
</script>